import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from "gatsby"
import Card from 'react-bootstrap/Card'
const VideosGrid = () => {
	const data = useStaticQuery(graphql`
		query {
			allYoutubeVideo(filter: { videoId: { nin: ["4N4Sox-lJtA", "f7VhGXGhdLs","IDeRskDmgLE","oJqR7yEig0A"] } }) {
				edges {
					node {
						title
						videoId
						publishedAt
						description
						thumbnail {
							height
							url
							width
						}
					}
				}
			}
		}
	`);

	return (
		<div class="row info-panel">
			{data.allYoutubeVideo.edges.map(({ node }) => {
				return (
					<div className="col-md-4 mb-4">
						<Card className="cardShadow p-0" style={{maxWidth:"400px"}}>
								<img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube video" />
								<Link to={"/modal-youtube#"+node.videoId} className="youtubeSpn" state={{ modal: true, width: "300px"}}>
									<img class="image-youtube" src={node.thumbnail.url} alt="youtube video" />
									<div class="image-youtube-overlay"></div>
								</Link>
						</Card>
					</div>);
			})}
		</div>
	);
};

export default VideosGrid;

// <Video key={node.videoId} {...node} />

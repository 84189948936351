import * as React from 'react'
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import VideosGrid from '../components/VideosGrid';

export default () => (
    <Layout>
      <Jumbotron className="bg-lightblue">
        <div class="container">
          <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
            <h2>Videos</h2>
            <p className="mt-4">
              Videos on a variety of tech topics, offered for free. These videos are a part of our paid training. If you like these, you'll love our premium courses.
            </p>
        </div>
        </div>
      </Jumbotron>
      <div class="container">
        <VideosGrid />
      </div>
    </Layout>
)
